import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import PrimaryBox from 'components/Internet_Access/The_DDNS_Service/Test_DDNS_Address/PrimaryBox';
import ConfigTable from "components/Internet_Access/The_DDNS_Service/Test_DDNS_Address/ConfigTable";
import NavButtons from 'components/Internet_Access/NavButtons';
import ForumBox from 'components/Internet_Access/The_DDNS_Service/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Checking your DDNS Address",
  "path": "/Internet_Access/The_DDNS_Service/Test_DDNS_Address/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Verify that your camera activated / updated your DDNS address",
  "image": "./IA_SearchThumb_DDNSTest.png",
  "social": "/images/Search/IA_SearchThumb_DDNSTest.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-DDNS_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='The INSTAR DDNS Service' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Verify that your camera activated / updated your DDNS address' image='/images/Search/IA_SearchThumb_DDNSService.png' twitter='/images/Search/IA_SearchThumb_DDNSService.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/Der_DDNS_Dienst/Testen_der_DDNS_Adresse/' locationFR='/fr/Internet_Access/The_DDNS_Service/Test_DDNS_Address/' crumbLabel="INSTAR DDNS" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "the-instar-ddns-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-instar-ddns-service",
        "aria-label": "the instar ddns service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The INSTAR DDNS Service`}</h1>
    <h2 {...{
      "id": "verify-that-your-camera-activated--updated-your-ddns-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#verify-that-your-camera-activated--updated-your-ddns-address",
        "aria-label": "verify that your camera activated  updated your ddns address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Verify that your camera activated / updated your DDNS address`}</h2>
    <p>{`This step is very easy. Please open the web interface of your camera and click on Network - `}<strong parentName="p">{`DDNS Service`}</strong>{`. Here you will find the Manufacturer Domain. This is the address with which you can access your camera over the internet. Remember this address! It normally consists of 4 letters and 2 numbers plus the server domain (e.g. ddns-instar.com). For example, if it used the servers xxxxx.ddns-instar.com or xxxxx.ddns-instar.de then the "x" stands for the 6 letters/numbers. Please make sure that the third party DDNS is set to None. Now please click on System - Info. The DDNS Status has to be displayed as successful.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "620px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/59c3c56bead3a379a52981950c3da194/935bc/Port_Forwarding_EN_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB07Cgf//EABcQAQEBAQAAAAAAAAAAAAAAAAABERD/2gAIAQEAAQUClRrE5//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/Aqv/xAAaEAACAgMAAAAAAAAAAAAAAAAAARExEEGB/9oACAEBAAE/IWRYeFs6EiK4/9oADAMBAAIAAwAAABDTz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAMAAQUAAAAAAAAAAAAAAAABEXEhMUGRof/aAAgBAQABPxBm20ToxhCrnoJol6bmSn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/59c3c56bead3a379a52981950c3da194/e4706/Port_Forwarding_EN_02.avif 230w", "/en/static/59c3c56bead3a379a52981950c3da194/d1af7/Port_Forwarding_EN_02.avif 460w", "/en/static/59c3c56bead3a379a52981950c3da194/e25ad/Port_Forwarding_EN_02.avif 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/59c3c56bead3a379a52981950c3da194/a0b58/Port_Forwarding_EN_02.webp 230w", "/en/static/59c3c56bead3a379a52981950c3da194/bc10c/Port_Forwarding_EN_02.webp 460w", "/en/static/59c3c56bead3a379a52981950c3da194/cf77e/Port_Forwarding_EN_02.webp 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/59c3c56bead3a379a52981950c3da194/e83b4/Port_Forwarding_EN_02.jpg 230w", "/en/static/59c3c56bead3a379a52981950c3da194/e41a8/Port_Forwarding_EN_02.jpg 460w", "/en/static/59c3c56bead3a379a52981950c3da194/935bc/Port_Forwarding_EN_02.jpg 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/59c3c56bead3a379a52981950c3da194/935bc/Port_Forwarding_EN_02.jpg",
              "alt": "WebUI DDNS Status",
              "title": "WebUI DDNS Status",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If in some case this DDNS address is missing, please contact the INSTAR Support. If the status is unsuccessful, your camera might have a problem connecting to the internet. You can try restarting the camera and check its IP configuration - especially the DNS Server and Gateway. Please contact the INSTAR support team if the problem persists.`}</p>
    <p>{`You can also check your DDNS address manually with a small program called `}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Nslookup"
      }}>{`nslookup`}</a>{`. Open your command prompt (in Windows: go to Start and type in `}<strong parentName="p">{`cmd`}</strong>{`) and type `}<em parentName="p">{`nslookup`}</em>{` to start the program. Now type in your DDNS address and wait for the response (see screenshots below). The IP address that is displayed has to be identical with the camera´s WAN IP address. You can check your WAN IP inside your router. Or open the website `}<a parentName="p" {...{
        "href": "http://wieistmeineip.de/"
      }}>{`wieistmeineip.de`}</a>{` and compare the displayed IPv4 address with the nslookup result.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "620px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cf607e9fdc50d52091a6487532f9e867/2a195/Nslookup_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA2klEQVQoz63QTWqEMBTA8Zeom8agEEEFF6LjJXTlDdx5jZmt6aRVQ0YM6qKbHqNHLCRdtJsy/fjtXuDPewTWl1e1rDeltNbLsozjOE3TVYhhGDh/FEJwzoW4zoaUUs7zs5Tr5fJ2PkPf90VRnIw8z5MkiaIoTVPGmO/7hJDAoJQGQYAQAgDHcQDgSSlo2xZjzBh7MCilhJAwDD3PAwN9Yl9svGkNdV0DAMYY7mbj4zigaRo7o69+EP9p8z+f/Q3XdT/iX3yYjfd9h67rsiwry7K4W1VVcRxv2/YOhktApOlb8/sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf607e9fdc50d52091a6487532f9e867/e4706/Nslookup_01.avif 230w", "/en/static/cf607e9fdc50d52091a6487532f9e867/d1af7/Nslookup_01.avif 460w", "/en/static/cf607e9fdc50d52091a6487532f9e867/e25ad/Nslookup_01.avif 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cf607e9fdc50d52091a6487532f9e867/a0b58/Nslookup_01.webp 230w", "/en/static/cf607e9fdc50d52091a6487532f9e867/bc10c/Nslookup_01.webp 460w", "/en/static/cf607e9fdc50d52091a6487532f9e867/cf77e/Nslookup_01.webp 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf607e9fdc50d52091a6487532f9e867/81c8e/Nslookup_01.png 230w", "/en/static/cf607e9fdc50d52091a6487532f9e867/08a84/Nslookup_01.png 460w", "/en/static/cf607e9fdc50d52091a6487532f9e867/2a195/Nslookup_01.png 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cf607e9fdc50d52091a6487532f9e867/2a195/Nslookup_01.png",
              "alt": "nslookup command line interface",
              "title": "nslookup command line interface",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open your command prompt and type nslookup to start the program.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "620px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c1055792f58a3ad9cebcef2b63a65bd0/2a195/Nslookup_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABBElEQVQoz63NO2uDUBjG8UctFITjMV7iVjxUe9Lb4GXJ0HTIKrilHyNLpkqlXkAtGCgW/Rj9iqURellaC/0Nz/DCnxf1c181T3VVtW3bNE2WZXmep+lDktwnSZKm6bjFQVmWZVE8lmW73b7udojj2GbMcV3HdU9s2zAMXddN09Q0jRAiy7KqqoqiEEIopYIgABi33u9xt9k4tn21WFxyfrNc3q5Wvu97nkcpxYHwxccFwEvXIV6vL3T9dDY7n8+vLcsiRJAk/GiMh2FA4PsAjkXxCPgl+h73fY8gCACIoojJ/ikOwxCAJEnCZOOn99jzvL9+/oyjKGKMcc7PJuOcM8a6rnsDM81GYp61MmMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1055792f58a3ad9cebcef2b63a65bd0/e4706/Nslookup_02.avif 230w", "/en/static/c1055792f58a3ad9cebcef2b63a65bd0/d1af7/Nslookup_02.avif 460w", "/en/static/c1055792f58a3ad9cebcef2b63a65bd0/e25ad/Nslookup_02.avif 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c1055792f58a3ad9cebcef2b63a65bd0/a0b58/Nslookup_02.webp 230w", "/en/static/c1055792f58a3ad9cebcef2b63a65bd0/bc10c/Nslookup_02.webp 460w", "/en/static/c1055792f58a3ad9cebcef2b63a65bd0/cf77e/Nslookup_02.webp 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c1055792f58a3ad9cebcef2b63a65bd0/81c8e/Nslookup_02.png 230w", "/en/static/c1055792f58a3ad9cebcef2b63a65bd0/08a84/Nslookup_02.png 460w", "/en/static/c1055792f58a3ad9cebcef2b63a65bd0/2a195/Nslookup_02.png 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c1055792f58a3ad9cebcef2b63a65bd0/2a195/Nslookup_02.png",
              "alt": "nslookup command line interface",
              "title": "nslookup command line interface",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in your personal DDNS address and press Enter.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "620px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8accd22c46de6d7bfe4d558253078d79/2a195/Nslookup_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAABK0lEQVQoz43PQWvCMBTA8ZdmtXSklRpPGhTTyKwibghC6W2gB0/iyX0OwbYKrWjR7CBjVPFz7BsO6sAx0fnnnQI/3gtsPg7JZpskayllkiRRFMVRPJ+HfuAHgR+GYeD7i8ViuVzGcbxard6lXEv5OZ1+zWYwHA6r1Wq9Xrdtu1KpUEoty6KU5vN5Xdc1TTMM4zGLEIIQUhTlAWMAkLsdjMfjExZC9Ho913Wfs0zTBACEEFx0ejzs9zAYDMrlsrDtRhav1Qgh19hvfDweYTQaMcaarVaj2Ww4zku3axUK9+K3yaRQLD6VSq+O4wrhCUGzg290xp7nAYCGsamqRi5HVBVf3/kXdzodAMAYA0Kn+TdFUX5wu92+/cPLzrjf7zPGOOe1u+OcM8bSNP0GG+JKtng63SQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8accd22c46de6d7bfe4d558253078d79/e4706/Nslookup_03.avif 230w", "/en/static/8accd22c46de6d7bfe4d558253078d79/d1af7/Nslookup_03.avif 460w", "/en/static/8accd22c46de6d7bfe4d558253078d79/e25ad/Nslookup_03.avif 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8accd22c46de6d7bfe4d558253078d79/a0b58/Nslookup_03.webp 230w", "/en/static/8accd22c46de6d7bfe4d558253078d79/bc10c/Nslookup_03.webp 460w", "/en/static/8accd22c46de6d7bfe4d558253078d79/cf77e/Nslookup_03.webp 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8accd22c46de6d7bfe4d558253078d79/81c8e/Nslookup_03.png 230w", "/en/static/8accd22c46de6d7bfe4d558253078d79/08a84/Nslookup_03.png 460w", "/en/static/8accd22c46de6d7bfe4d558253078d79/2a195/Nslookup_03.png 620w"],
              "sizes": "(max-width: 620px) 100vw, 620px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8accd22c46de6d7bfe4d558253078d79/2a195/Nslookup_03.png",
              "alt": "nslookup command line interface",
              "title": "nslookup command line interface",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The displayed IPv4 address has to be identical with your WAN IP address for the DDNS connection to work.`}</p>
    <h4 {...{
      "id": "port-forwarding--portforwarding--firewall--nat--or-port-rules--virtual-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#port-forwarding--portforwarding--firewall--nat--or-port-rules--virtual-server",
        "aria-label": "port forwarding  portforwarding  firewall  nat  or port rules  virtual server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Port Forwarding / Portforwarding / Firewall / NAT- or Port-Rules / Virtual Server`}</h4>
    <p>{`In the third and last step we have to tell the router to which IP and which Port he has to connect if you access from the internet in order to access your camera. Therefor you have to set a rule in your router. In most routers this is called port forwarding but some other routers might name it NAT, Firewall or Port rules.`}</p>
    <p>{`Each router also used different expressions for the settings. Our goal is it to make you understand the settings so that you can easily fill out your own routers information for the port forwarding. In our sample we use the AVM Fritz!Box again to show you how to set it. To open the router just use the IP address which we filled in as gateway above and open it in the web browser. You should now see the login screen for your router. In the Fritz!Box you find this settings by clicking "Internet" - "Portfreigabe". Normally all routers need the same following data:`}</p>
    <ConfigTable mdxType="ConfigTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`If you want to set up more than one camera you have to make sure that for each camera you will create a port rule in your router. The external Port has to be unique for every camera. We recommend to increase the Port number by one. So first camera is using Port 80 and the second one should use Port 81 and the third camera Port 82 and so on. After you created the Port rule for each camera you should type in the DDNS address + the Port number. For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://xxxxx.ddns-instar.com:80`}</code></pre></div>
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <Link to="/Internet_Access/The_DDNS_Service/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Return</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      